import React, { useEffect } from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation
} from "react-router-dom";

import isAuthenticate from "./modules/Secure/Secure";
// import Accueil from './frontend/Accueil';
import Connexion from "./frontend/Connexion";
import AllCourses from "./frontend/dashboards/AllCoursesV2";
import Downloads from "./frontend/dashboards/Downloads";
import Forum from "./frontend/dashboards/Forum";
import MyCourses from "./frontend/dashboards/MyCourses";
import QuizCours from "./frontend/dashboards/QuizCours";
import SessionDetails from "./frontend/dashboards/SessionDetails";
import SessionStart from "./frontend/dashboards/SessionStart";
// import MyCertificatV2 from './frontend/dashboards/MyCertificatesV2';
import MyCertificatesV3 from "./frontend/dashboards/MyCertificatesV3";
// import MyCertificates from './frontend/dashboards/MyCertificates';
import Apprenants from "./backend/Apprenants";
import Module from "./backend/Module";
import Quiz from "./backend/Quiz";
import TableauDeBord from "./backend/TableauDeBord";
import ForumDetail from "./frontend/dashboards/ForumDetail";
//import ModuleDetails from './backend/ModuleDetails';
import "react-toastify/dist/ReactToastify.css";
import Certificat from "./backend/Certificat";
import CreateQcm from "./backend/components/CreateQcm";
import ModuleDetailsV2 from "./backend/ModuleDetailsV2";
// import FicheEvaluation from "./frontend/components/FicheEvaluation";
// import FicheIdentificaton from "./frontend/components/FicheIdentification";
import MyProfile from "./frontend/dashboards/MyProfile";
import SliderContentDetailsSession from "./frontend/dashboards/SliderContentDetailsSession";
import ResetPassword from "./frontend/ResetPassword";
import TokenExpirated from "./frontend/TokenExpirated";
import WelcomeV3 from "./frontend/WelcomeV3";
import isFirstConnexion from "./modules/Permission/Permission";
// import ScrollToTop from './ScrollToTop';
// import { Switch } from '@material-ui/core';
import { isMobile } from "react-device-detect";
import AdminContactMessages from "./backend/AdminContactMessages";
import MobileDetect from "./modules/MobileDetect/MobileDetect";
// import ListeIdentification from './backend/ListeIdentification';
import { ToastContainer } from "react-toastify";
import packageJson from "../package.json";
import AdminUserManager from "./backend/AdminUserManager";
import Extraction from "./backend/Extraction";
import ListeIdentificationV2 from "./backend/ListeIdentificationV2";
import Performances from "./backend/Performances";
import withClearCache from "./ClearCache";
import Inscription from "./frontend/Inscription";
import LegalNotice from "./frontend/LegalNotice";
import PrivacyPolicy from "./frontend/PrivacyPolicy";
import { getBuildDate } from "./utils/utils";
import FicheIdentificationV2 from "./frontend/components/FicheIdentificationV2";
import FicheEvaluationV2 from "./frontend/components/FicheEvaluationV2";
import { fetchListApprenants } from "./redux/apprenantList/apprenantListAction";
import { useDispatch } from "react-redux";
import { fetchListModules } from "./redux/modules/moduleAction";
import SessionDetailsV2 from "./frontend/dashboards/SessionDetailsV2";
import WelcomeV4 from "./frontend/components/WelcomeV4";
import WelcomePage from "./frontend/WelcomePage";
import Presentation from "./frontend/dashboards/newsModules/Presentation";
import AllPersonnages from "./frontend/dashboards/newsModules/AllPersonnages";
import TerminerPresentation from "./frontend/dashboards/newsModules/TerminerPresentation";
import ListSousModules from "./frontend/dashboards/newsModules/ListSousModules";
import ChoisirPersonnages from "./frontend/dashboards/newsModules/ChoisirPersonnages";
import Thematiques from "./backend/Thematiques";
import ThematiqueDetails from "./backend/ThematiqueDetails";
import Personnage from "./backend/Personnage";
import SliderContentContainer from "./frontend/dashboards/SliderContentContainer";
import AboutDefaru from "./frontend/dashboards/AboutDefaru";
import ListSousModulesUser from "./frontend/dashboards/newsModules/ListSousModulesUser";
// import CheckImage from "./modules/CheckImage";

// import { useDispatch } from "react-redux";
// import { fetchListApprenants } from "./redux/apprenantList/apprenantListAction";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  console.log("Build date:", getBuildDate(packageJson.buildDate));
  var isLogInExpired = isAuthenticate();
  var isFirst = isFirstConnexion();
  const dispatch = useDispatch();
  // const location = useLocation()
  let pathname = window.location.pathname;
  const token = window.localStorage.getItem("userToken");
  var userType = window.localStorage.getItem("userType");
  

  useEffect(() => {
    // console.log("pathname", location);
    window.scroll(0,0)
  }, [pathname])

  useEffect(() => {
    if (userType === "admin" && token) {
      dispatch(fetchListModules());
      dispatch(fetchListApprenants());
    }
  }, [dispatch, token, userType]);

  let path = window.location.pathname

  if (isMobile && path !== '/mentions-legales' && path !== '/politique-de-confidentialite') {
    return <MobileDetect />;
  } else {
    return (
      <Router>
        <div className="router-container">
          <ToastContainer />
          <Switch>
            {/* <Route exact path="/check-image" component={CheckImage} /> */}
            <Route exact path="/" component={WelcomeV4} />
            <Route
              path="/politique-de-confidentialite"
              component={PrivacyPolicy}
            />
            <Route path="/mentions-legales" component={LegalNotice} />
            {/*  <Route path="/welcome" component={WelcomeV3} /> */}
            <Route path="/connexion" component={Connexion} />
            <Route path="/inscription" component={Inscription} />
            <Route
              path="/modifier-mon-mot-de-passe"
              component={ResetPassword}
            />

            <Route path="/welcome">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <WelcomePage />
              )}
            </Route>

            <Route path="/tous-les-modules">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <AllCourses />
              )}
            </Route>

            <Route path="/a-propos">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <AboutDefaru />
              )}
            </Route>

            <Route path="/presentation-module/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <Presentation />
              )}
            </Route>

            <Route path="/presention-personnages/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <AllPersonnages />
              )}
            </Route>

            <Route path="/choisir-personnage/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <ChoisirPersonnages />
              )}
            </Route>

            <Route path="/terminer-presentation/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <TerminerPresentation />
              )}
            </Route>

            <Route path="/sous-modules/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <ListSousModules />
              )}
            </Route>

            <Route path="/mon-parcours/sous-modules/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <ListSousModulesUser />
              )}
            </Route>

            <Route path="/mon-parcours">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <MyCourses />
              )}
            </Route>
            <Route path="/telechargements">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <Downloads />
              )}
            </Route>
            <Route path="/forum">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                userType === "admin" ? (
                  <Forum />
                ) : (
                  <Redirect to={`/welcome`} />
                )
              ) : (
                <Forum />
              )}
            </Route>
            <Route path="/mon-profile">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <MyProfile />
              )}
            </Route>
            <Route
              path="/cours-details/:id"
              component={SessionDetails}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else if (isFirst === true) {
                  return <Redirect to={`/welcome`} />;
                } else {
                  return <SessionDetailsV2 />;
                }
              }}
            />
            <Route
              path="/parcours-cours-details/:id"
              component={SessionDetails}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else if (isFirst === true) {
                  return <Redirect to={`/welcome`} />;
                } else {
                  return <SessionDetailsV2 />;
                }
              }}
            />
            {/* <Route path="/cours-details/:id">
            {isLogInExpired ? <Redirect to="/connexion" /> : <SessionDetails />}
          </Route> */}
            <Route path="/cours-start/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst === true ? (
                <Redirect to={`/welcome`} />
              ) : (
                <SessionStart />
              )}
            </Route>
            <Route path="/quiz-cours">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst === true ? (
                <Redirect to={`/welcome`} />
              ) : (
                <QuizCours />
              )}
            </Route>
            <Route path="/mon-certificat">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst === true ? (
                <Redirect to={`/welcome`} />
              ) : (
                <MyCertificatesV3 />
              )}
            </Route>
            <Route path="/fiche-identification">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <FicheIdentificationV2 />
              )}
            </Route>
            <Route path="/fiche-evaluation/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <FicheEvaluationV2 />
              )}
            </Route>
            <Route
              path="/details-forum/:id"
              component={ForumDetail}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else if (isFirst === true) {
                  return <Redirect to={`/welcome`} />;
                } else {
                  return <ForumDetail />;
                }
              }}
            />
            <Route
              path="/session-start/:component/:id"
              component={SliderContentContainer}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else if (isFirst === true) {
                  return <Redirect to={`/welcome`} />;
                } else {
                  return <SliderContentContainer />;
                }
              }}
            />
            {/* <Route path="/session-start/:id">
            {isLogInExpired ? (
              <Redirect to="/connexion" />
            ) : (
              <SliderContentDetailsSession />
            )}
          </Route> */}
            <Route path="/token-expire" component={TokenExpirated} />

            {/* Routing Backend  */}
            <Route path="/tableau-de-bord">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <TableauDeBord />
              )}
            </Route>

            <Route path="/performances">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Performances />}
            </Route>

            <Route path="/extraction">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Extraction />}
            </Route>

            <Route path="/gestion-messages-contact">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <AdminContactMessages />
              )}
            </Route>

            <Route path="/apprenants">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Apprenants />}
            </Route>

            <Route path="/parametres">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <AdminUserManager />
              )}
            </Route>

            <Route path="/liste-des-identifications">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <ListeIdentificationV2 />
              )}
            </Route>

            <Route path="/quiz-admin">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Quiz />}
            </Route>
            <Route path="/module">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Module />}
            </Route>

            <Route
              path="/module-details/:id"
              component={ModuleDetailsV2}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else {
                  return <ModuleDetailsV2 />;
                }
              }}
            />
            <Route exact path="/thematiques">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Thematiques />}
            </Route>
            <Route
              path="/thematique-details/:id"
              component={ThematiqueDetails}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else {
                  return <ThematiqueDetails />;
                }
              }}
            />
            <Route exact path="/personnages">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Personnage />}
            </Route>

            <Route path="/qcm">
              {isLogInExpired ? <Redirect to="/connexion" /> : <CreateQcm />}
            </Route>

            <Route path="/certificats">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Certificat />}
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
